<template>
  <el-dialog
    :title="title"
    :visible.sync="dialogVisible"
    custom-class="shelves-dialog"
    :before-close="cancelFn"
  >
    <div class="dialog-cont">
      <p class="cont-title" v-show="this.fromType !== 3">{{ contTitle }}</p>
      <el-form :model="shelvesForm" :rules="rules" ref="shelvesForm">
        <el-form-item
          v-show="this.fromType !== 3"
          prop="checkValue"
          :rules="[
            {
              required: true,
              message: $t('placeholder.plsSel'),
              trigger: 'change',
            },
          ]"
        >
          <el-checkbox-group v-model="shelvesForm.checkValue">
            <el-checkbox
              :label="item.value"
              :disabled="item.disabled"
              v-for="item in checkListArr"
              :key="item.value"
            >
              {{ lang === 'zh' ? item.label : item.labelEn }}
            </el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item
          v-if="fromType !== 1"
          :label="$t('productCenter.reasonForDelisting') + '：'"
          prop="reasonForDelisting"
          label-width="100px"
          :rules="[
            {
              required: true,
              message: $t('placeholder.plsInput'),
              trigger: 'blur',
            },
          ]"
        >
          <el-input
            type="textarea"
            :rows="2"
            :placeholder="$t('placeholder.plsInput')"
            v-model="shelvesForm.reasonForDelisting"
            :maxlength="500"
          ></el-input>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="cancelFn">{{ $t('other.cancel') }}</el-button>
      <el-button type="primary" @click="submitForm" :loading="confirmlloading">
        {{ $t('other.confirm') }}
      </el-button>
    </span>
  </el-dialog>
</template>
<script>
  import { PlatformProductsInteractor } from '@/core'
  import { mapGetters } from 'vuex'
  export default {
    name: 'OnAndOffShelves',
    props: {
      // 1是上架到商城弹窗 2是从商城下架弹窗 3 是从三方平台下架
      fromType: {
        type: Number,
        default: 1,
      },
      // 上架平台code
      platformCodes: {
        type: Array,
        default: () => {
          return []
        },
      },
      // 产品id
      productId: {
        type: String,
        required: true,
      },
      onAndOffShelvesShow: {
        type: Boolean,
      },
    },
    computed: {
      ...mapGetters({
        lang: 'settings/language',
      }),
      title() {
        return this.fromType === 1
          ? this.$t('productCenter.addProductsToTheMall')
          : this.fromType === 2
          ? this.$t('productCenter.removedFromTheMall')
          : this.$t('productCenter.removedFromThirdParties')
      },
      contTitle() {
        return this.fromType === 1
          ? this.$t('productCenter.confirmToPutThisProductOnTheMall')
          : this.fromType === 2
          ? this.$t(
              'productCenter.removedFromTheFollowingAlreadyOnTheShelvesMallPlatforms'
            )
          : this.$t(
              'productCenter.removedFromTheFollowingAlreadyOnTheShelvesThirdPartyPlatforms'
            )
      },
      dialogVisible: {
        get() {
          return this.onAndOffShelvesShow
        },
        set(val) {
          this.$emit('update:onAndOffShelvesShow', val)
        },
      },
      checkListArr() {
        this.checkList = JSON.parse(JSON.stringify(this.checkListTemplate))
        this.shelvesForm.checkValue = []
        this.shelvesForm.reasonForDelisting = ''
        this.$nextTick(() => {
          this.$refs.shelvesForm && this.$refs.shelvesForm.clearValidate()
        })
        if (this.fromType === 1) {
          return this.checkList.map((item) => {
            if (this.platformCodes.includes(item.value)) {
              item.disabled = true
            }
            return item
          })
        } else if (this.fromType === 2) {
          let availableArr = ['RP', 'RG', 'SW']
          let arr = JSON.parse(JSON.stringify(this.platformCodes))
          let valueArr = []
          for (let k of arr) {
            if (availableArr.includes(k)) {
              valueArr.push(k)
            }
          }
          this.shelvesForm.checkValue = valueArr
          return this.checkList.map((item) => {
            if (!this.platformCodes.includes(item.value)) {
              item.disabled = true
            }
            return item
          })
        } else {
          this.shelvesForm.checkValue = ['SAGE']
          return [
            {
              label: 'SAGE（68786）',
              labelEn: 'SAGE(68786)',
              value: 'SAGE',
              disabled: false,
            },
          ]
        }
      },
    },
    data() {
      return {
        confirmlloading: false,
        shelvesForm: {
          checkValue: [],
          reasonForDelisting: '',
        },
        checkListTemplate: [
          {
            label: 'RP 商城',
            labelEn: 'RP Site',
            value: 'RP',
            disabled: false,
          },
          {
            label: 'RG 商城',
            labelEn: 'RG Site',
            value: 'RG',
            disabled: false,
          },
          {
            label: 'SW 商城',
            labelEn: 'SW Site',
            value: 'SW',
            disabled: false,
          },
        ],
        checkList: [],
        rules: {},
      }
    },
    methods: {
      // 取消
      cancelFn() {
        this.dialogVisible = false
        this.$refs.shelvesForm.resetFields()
      },
      // 确定
      submitForm() {
        this.$refs.shelvesForm.validate(async (valid) => {
          if (valid) {
            let params = {
              platformCodes: this.shelvesForm.checkValue,
              reason: this.shelvesForm.reasonForDelisting,
              erpProductId: this.productId,
            }
            this.confirmlloading = true
            let res =
              this.fromType === 1
                ? await PlatformProductsInteractor.upShelfApi(params)
                : await PlatformProductsInteractor.downShelfApi(params)
            this.confirmlloading = false
            if (res?.code === '000000') {
              const msg = this.fromType == 3 ? this.$t('productCenter.productSold') : 'SUCCESS'
              this.$baseMessage(
                msg,
                'success',
                false,
                'erp-hey-message-success'
              )
              this.$emit('onAndOffShelvesSuccess')
            }
            this.shelvesForm = {
              checkValue: [],
              reasonForDelisting: '',
            }
            this.dialogVisible = false
          } else {
            console.log('error submit!!')
            return false
          }
        })
      },
    },
  }
</script>
<style lang="scss" scope>
  .shelves-dialog {
    width: 480px !important;
    .dialog-cont {
      padding: 0 20px;
    }
    .cont-title {
      margin-bottom: 20px;
    }
  }
</style>
