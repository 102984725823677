<template>
  <el-dialog
    :title="$t(title)"
    :visible.sync="dialogFormVisible"
    width="65%"
    @close="close"
    v-if="dialogFormVisible"
    :close-on-click-modal="false"
  >
    <div class="dialog-content">
      <el-table
        :data="tableData"
        border
        class="w100"
        v-show="!noSpec"
        v-loading="noSpec"
      >
        <!-- 编码 -->
        <el-table-column
          prop="sku"
          :label="$t('productCenter.Code')"
          width="130"
          align="center"
        ></el-table-column>
        <!-- 规格图 -->
        <el-table-column
          prop="image"
          :label="$t('productCenter.SpecificationDrawing')"
          width="120"
          align="center"
        >
          <template slot-scope="scope">
            <el-image
              v-if="scope.row.image"
              :src="scope.row.image"
              @click="handlePreview(scope.row.image)"
              class="c_pointer"
            />
            <span v-else>--</span>
          </template>
        </el-table-column>
        <el-table-column
          width="120"
          align="center"
          v-for="(item, index) of tableSpecsType"
          :key="index"
          :label="item"
          :prop="'specsValue' + (index + 1)"
        >
          <template slot-scope="{ row }">
            {{row['specsValue' + (index + 1)]}}{{$specShowSpilterator}}{{row['specsValueCn' + (index + 1)] || '--'}}
          </template>
        </el-table-column>
        <!-- 库存在仓数 -->
        <el-table-column
          prop="stockNum"
          :label="$t('productCenter.NumberOfStockInWarehouse')"
          align="center"
        >
          <template slot-scope="scope">
            <el-row v-if="scope.row.stockNum && scope.row.stockNum.length">
              <p v-for="(item, index) of scope.row.stockNum" :key="index">
                {{ getName(item,'warehouseName') }}：{{ item.num }}
              </p>
            </el-row>
            <span v-else>--</span>
          </template>
        </el-table-column>
        <!-- 库存可用量 -->
        <el-table-column
          prop="usableNum"
          :label="$t('productCenter.RemainingStock')"
          align="center"
        >
          <template slot-scope="scope">
            <el-row v-if="scope.row.usableNum && scope.row.usableNum.length">
              <p v-for="(item, index) of scope.row.usableNum" :key="index">
                {{ getName(item,'warehouseName') }}：{{ item.num }}
              </p>
            </el-row>
            <span v-else>--</span>
          </template>
        </el-table-column>
      </el-table>
      <el-table :data="tableData" border class="w100" v-show="noSpec">
        <!-- 编码 -->
        <el-table-column
          prop="sku"
          :label="$t('productCenter.Code')"
          width="130"
          align="center"
        ></el-table-column>
        <!-- 规格图 -->
        <el-table-column
          prop="image"
          :label="$t('productCenter.SpecificationDrawing')"
          width="120"
          align="center"
        >
          <template slot-scope="scope">
            <el-image
              v-if="scope.row.image"
              :src="scope.row.image"
              @click="handlePreview(scope.row.image)"
              class="c_pointer"
            />
            <span v-else>--</span>
          </template>
        </el-table-column>
        <el-table-column
          width="120"
          align="center"
          v-for="(item, index) of tableSpecsType"
          :key="index"
          :label="item"
          :prop="'specsValue' + (index + 1)"
        ></el-table-column>
        <!-- 库存在仓数 -->
        <el-table-column
          prop="stockNum"
          :label="$t('productCenter.NumberOfStockInWarehouse')"
          align="center"
        >
          <template slot-scope="scope">
            <el-row v-if="scope.row.stockNum && scope.row.stockNum.length">
              <p v-for="(item, index) of scope.row.stockNum" :key="index">
                {{ getName(item, 'warehouseName') }}：{{ item.num }}
              </p>
            </el-row>
            <span v-else>--</span>
          </template>
        </el-table-column>
        <!-- 库存可用量 -->
        <el-table-column
          prop="usableNum"
          :label="$t('productCenter.RemainingStock')"
          align="center"
        >
          <template slot-scope="scope">
            <el-row v-if="scope.row.usableNum && scope.row.usableNum.length">
              <p v-for="(item, index) of scope.row.usableNum" :key="index">
                {{ getName(item, 'warehouseName') }}：{{ item.num }}
              </p>
            </el-row>
            <span v-else>--</span>
          </template>
        </el-table-column>
      </el-table>

      <!-- 图片预览 -->
      <el-image-viewer
        v-if="showViewer"
        :on-close="closeViewer"
        :url-list="[imgUrl]"
      />
    </div>
    <template #footer>
      <el-row class="text-center">
        <!-- 关闭 -->
        <el-button @click="close">{{ $t('productCenter.Close') }}</el-button>
      </el-row>
    </template>
  </el-dialog>
</template>

<script>
import { skuList } from '@/api/product/productList'
import nameMixin from '@/utils/name-mixin'

export default {
  name: 'ProSkuModel',
  mixins:[nameMixin],
  data() {
    return {
      // title: 'SKU列表',
      title: 'productCenter.SKUList',
      tableData: [],
      dialogFormVisible: false,
      tableSpecsType: [],
      showViewer: false, //图片预览参数
      imgUrl: '', //图片预览url
      noSpec: false,
    }
  },
  created() {},
  methods: {
    //查看
    showAddEdit(id) {
      this.getSkuList(id)
      this.dialogFormVisible = true
    },

    //sku列表
    async getSkuList(id) {
      let response = await skuList({ productId: id })
      if (response.code == '000000') {
        this.tableData = response.data.skuSpecsVOList
        if (response.data.specsType) {
          this.noSpec = false
          this.tableSpecsType = response?.data?.specsType.split(',')
          this.tableSpecsTypeCn = response?.data?.specsTypeCn.split(',')
          if(this.tableSpecsTypeCn?.length) {
            this.tableSpecsType = this.tableSpecsType.map((spec,index) => {
              return `${spec}${this.$specShowSpilterator}${this.tableSpecsTypeCn[index]}`
            })
          }
        } else {
          this.noSpec = true
        }
      }
    },
    // 大图预览
    handlePreview(item) {
      this.imgUrl = item
      this.showViewer = true
    },
    closeViewer() {
      this.showViewer = false
    },

    //关闭
    close() {
      this.noSpec = false
      this.tableData = []
      this.tableSpecsType = []
      this.dialogFormVisible = false
    },
  },
}
</script>

<style scoped lang="scss">
.dialog-content {
  max-height: 50vh;
  min-height: 10vh;
  overflow: hidden;
  overflow-y: auto;
}
</style>
